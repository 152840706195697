/**
 * Enum representing the form controls for autocomplete.
 */
export enum AutocompleteFormControls {
  SEARCH = 'search',
}

/**
 * Enum representing the form controls for sign in.
 */
export enum SignInFormControls {
  LOGIN = 'login',
  PASSWORD = 'password',
}

/**
 * Enum representing the form controls for sign up.
 */
export enum SignUpFormControls {
  EMAIL = 'email',
  FIRST_NAME = 'first_name',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  PHONE = 'phone',
  TERMS = 'terms',
  POLICY = 'privacyPolicy',
}

/**
 * Enum representing the form controls for settings.
 */
export enum SettingsFormControls {
  TERMS = 'terms',
  POLICY = 'privacyPolicy',
  EMAIL_NOTIFICATIONS = 'emailNotifications',
}

/**
 * Enum representing the form controls for resetting password.
 */
export enum ResetPasswordFormControls {
  EMAIL = 'email',
  PASSWORD = 'password',
  NEW_PASSWORD = 'newPassword',
  SMS_CODE = 'smsCode',
  PHONE = 'phone',
}

/**
 * Enum representing the form controls for new password.
 */
export enum NewPasswordFormControls {
  PASSWORD = 'password',
  NEW_PASSWORD = 'newPassword',
  SMS_CODE = 'smsCode',
  PHONE = 'phone',
}

/**
 * Enum representing the form controls for configuration.
 */
export enum ConfigurationFormControls {
  PHONE = 'phone',
}

/**
 * Enum representing the form controls for adding a friend.
 */
export enum AddFriendFormControls {
  PHONE = 'phone',
  EMAIL = 'email',
  METHOD = 'method',
}

/**
 * Enum representing the form controls for search.
 */
export enum SearchFormControls {
  SEARCH = 's',
}

/**
 * Enum representing the form controls for address.
 */
export enum AddressFormControls {
  NAME = 'name',
  CITY = 'city',
  STREET = 'street',
  POSTCODE = 'postcode',
  HOUSE_NUMBER = 'house_number',
  BUILDING_NUMBER = 'building_number',
  IS_DEFAULT = 'is_default',
}

/**
 * Enum representing the form controls for filters.
 */
export enum FiltersFormControls {
  PAGE = 'page',
  ITEMS_PER_PAGE = 'itemsPerPage',
  SEARCH = 'search',
  SORT_FIELD = 'sortField',
  SORT_DIRECTION = 'sortDirection',
}

/**
 * Enum representing the form controls for restaurant filters.
 */
export enum RestaurantsFiltersFormControls {
  IS_OPEN = 'open',
  TABLE_BOOK = 'table_book',
  PROMOTIONS = 'promotions',
  FREE_DELIVERY = 'free_delivery',
  NEW_PRODUCTS = 'news',
  RESTAURANT_TAGS = 'restaurant_tags',
  CITY = 'city',
  SEARCH = 's',
  LOCALE = 'locale',
}

/**
 * Enum representing the form controls for sorting restaurants.
 */
export enum RestaurantsOrderByFormControls {
  DISTANCE = 'distance',
}

/**
 * Enum representing the form controls for sorting.
 */
export enum SortFormControls {
  SORT_FIELD = 'sortField',
  SORT_DIRECTION = 'sortDirection',
}

/**
 * Enum representing the form controls for booking a table.
 */
export enum BookTableFormControls {
  PEOPLE_NUMBER = 'people_number',
  START = 'start',
  NAME = 'name',
  DESCRIPTION = 'description',
  PHONE = 'phone',
  DATE = 'date',
  HOUR = 'hour',
}

/**
 * Enum representing the form controls for changing password.
 */
export enum ChangePasswordFormControls {
  OLD_PASSWORD = 'oldPassword',
  NEW_PASSWORD = 'newPassword',
  CONFIRM_PASSWORD = 'confirmPassword',
}

/**
 * Enum representing the form controls for redeeming a voucher.
 */
export enum RedeemVoucherFormControls {
  VOUCHER = 'voucher',
}

/**
 * Enum representing the form controls for contact information.
 */
export enum ContactFormControls {
  NAME = 'name',
  EMAIL = 'email',
  TITLE = 'title',
  MESSAGE = 'message',
  RECAPTCHA_TOKEN = 'site_key',
}
